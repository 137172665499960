import * as React from "react"
import { ButtonLink } from "@allied/react-web/Button"
import { Meta } from "@allied/react-web/Meta"

export default function NotFound(): React.JSX.Element {
  return (
    <>
      <Meta>
        {{
          title: "404 Page not found"
        }}
      </Meta>
      {/* 
        @note: do not remove, make sure to match the html element on the homepage
        @issue: react-snap do not handle 404 page correctly
        @ref: https://gitlab.allied.com.sg/web/acem-singapore/-/merge_requests/30
      */}
      <div></div><div></div>
      <div className="h-screen flex flex-col justify-center items-center">
        <div className="my-4 flex flex-col gap-4 justify-center items-center">
          <h1 className="text-8xl">
            404
          </h1>
          <h2 className="text-2xl md:!text-3xl">
            Oops! Page not found
          </h2>
        </div>
        <div className="my-6 flex flex-col justify-center items-center">
          <ButtonLink to="/" size="lg">
            Take Me Back To The Homepage
          </ButtonLink>
        </div>
      </div>
    </>
  )
}
