import * as React from "react"

type BreadcrumbItemProps = {
  children?: React.ReactNode
  separate?: boolean
}

export function BreadcrumbItem(props: BreadcrumbItemProps): React.JSX.Element {
  const {
    separate = false
  } = props

  const separateClassNames = separate ? "before:content-['/']" : ""

  return (
    <li className={"flex flex-row gap-2 " + separateClassNames}>
      {props.children}
    </li>
  )
}
