import * as React from "react"
import {
  RouteProps, Route,
  Navigate, NavigateProps,
  generatePath, useParams
} from "react-router-dom"

import Guest from "./layouts/Guest"
import Cart from "./pages/Cart"
import Compare from "./pages/Compare"
import CarBrands from "./pages/CarBrands"
import ContainerEnquiry from "./pages/ContainerEnquiry"
import GeneralEnquiry from "./pages/GeneralEnquiry"
import NotFound from "./pages/NotFound"
import Policy from "./pages/Policy"
import Principals from "./pages/Principals"
import Products from "./pages/Products"
import ProductDetail from "./pages/ProductDetail"
import Home from "./pages/Home"
import { brands } from "./services/static/Product"

const NavigateParams = ({ to, ...props }: NavigateProps) => {
  const params = useParams()
  return <Navigate {...props} to={generatePath(to.toString(), params)} />
}

const guests: RouteProps[] = [
  { path: "/", element: <Home /> },
  { path: "/home", element: <Navigate replace to="/" /> },
  { path: "/cart", element: <Cart /> },
  { path: "/compare", element: <Compare /> },
  { path: "/car-brands", element: <CarBrands /> },
  { path: "/container-enquiry", element: <ContainerEnquiry /> },
  { path: "/general-enquiry", element: <GeneralEnquiry /> },
  { path: "/products", element: <Products /> },
  { path: "/products/:slug", element: <ProductDetail />, caseSensitive: false },
  { path: "/principals", element: <Principals /> },
  { path: "/security-privacy-policy", element: <Policy /> },
  { path: "/product", element: <Navigate replace to="/products" /> },
  { path: "/product/:slug", element: <NavigateParams replace to="/products/:slug" /> },
  { path: "/bosch-products", element: <Navigate replace to="/products?principals=Bosch" /> },
  { path: "/liberty-products", element: <Navigate replace to="/products?principals=Liberty" /> },
  { path: "/palma-products", element: <Navigate replace to="/products?principals=Palma" /> },
  { path: "*", element: <NotFound /> },
]
brands.forEach((brand) => {
  guests.push({
    path: `/car-products/${brand.slug}`,
    element: <Navigate replace to={`/products?brands=` + brand.name} />,
  })
})

export const routes: RouteProps[] = [
  {
    path: "/",
    element: <Guest />,
    children: guests.map((route, i: number) => {
      return <Route {...route} key={`guest-item-${i}`} />
    })
  },
]
