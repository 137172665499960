import * as React from "react"
import { BreadcrumbItem } from "./BreadcrumbItem"

type BreadcrumbProps = {
  appendClassNames?: string
  children?: {
    items: BreadcrumbItemProps[]
  }
}

type BreadcrumbItemProps = {
  content?: React.ReactNode
}

export function Breadcrumb(props: BreadcrumbProps): React.JSX.Element {
  const { appendClassNames } = props
  const { items = [] } = props.children || {}

  return (
    <ul className={
      "flex flex-wrap flex-row gap-2 " +
      appendClassNames
    }>
      {
        items.map((item, i: number) => {
          return (
            <BreadcrumbItem key={`breadcrumb-item-${i}`} separate={i !== 0}>
              {item.content}
            </BreadcrumbItem>
          )
        })
      }
    </ul>
  )
}
