import * as React from "react"
import { useNavigate } from "react-router-dom"
import { FaChevronLeft } from "react-icons/fa6"
import { Link } from "@allied/react-web/Router"

type ButtonBackProps = {
  children?: React.ReactNode
}

export function ButtonBack(props: ButtonBackProps): React.JSX.Element {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(-1)
  }

  return (
    <Link to="#" className="flex flex-row gap-1 items-center text-aglogis-primary font-roboto-mono font-light text-lg" onClick={handleClick}>
      <FaChevronLeft className="w-5 h-5" aria-hidden="true" />
      {props.children}
    </Link>
  )
}
