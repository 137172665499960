import * as React from "react"
import { Outlet } from "react-router-dom"
import { FaFacebookF } from "react-icons/fa6"
import { Header } from "@allied/react-web/Header"
import { Footer } from "@allied/react-web/Footer"
import { ButtonCart, CartContext } from "../components/Cart"
import { ToastContainer } from "../components/Toast"
import aglogisLogo from "./../assets/image/logo.png"

export default function Guest(): React.JSX.Element {
  const { total_quantities } = React.useContext(CartContext)

  return (
    <>
      <Header.Aglogis
        logoImage={aglogisLogo}
        logoUrl="/"
        menuItems={[
          {
            content: <>Home</>,
            to: "/",
          },
          {
            content: <>Categories</>,
            to: "/",
            items: [
              {
                content: <>All Products</>,
                to: "/products"
              },
              {
                content: <>Bosch Products</>,
                to: "/products?principals=Bosch"
              },
              // {
              //   content: <>Liberty Products</>,
              //   to: "/products?principals=Liberty"
              // },
              {
                content: <>Palma Products</>,
                to: "/products?principals=Palma"
              },
              {
                content: <>Container Spare Part</>,
                to: "/products?types=Container+Spare+Part"
              },
            ]
          },
          {
            content: <>Brands</>,
            to: "/car-brands"
          },
          {
            content: <>Containers</>,
            to: "/container-enquiry"
          },
          {
            content: <>Principals</>,
            to: "/principals"
          },
          {
            content: <>Contact Us</>,
            to: "/general-enquiry"
          },
        ]}
        rightChildren={
          <ButtonCart to="/cart" totalItems={total_quantities}>
            Cart
          </ButtonCart>
        }
        sticky />
      <ToastContainer />
      <div className="min-h-screen">
        <Outlet />
      </div>
      <Footer.Aglogis
        menuItems={[
          {
            heading: "About Aglogis",
            items: [
              {
                content: "Our Container",
                to: "/container-enquiry",
              },
              {
                content: "Principals",
                to: "/principals",
              },
            ]
          },
          {
            heading: "Aglogis Resources",
            items: [
              {
                content: "My Enquiry",
                to: "/cart",
              },
              {
                content: "Security & Privacy Policy",
                to: "/security-privacy-policy",
              },
              {
                content: "Compare Products",
                to: "/compare",
              },
            ]
          },
        ]}
        mediaItems={[
          {
            to: "https://www.facebook.com/aglogis/",
            content: <FaFacebookF className="w-5 h-5" aria-hidden="true" />,
            target: "_blank"
          },
        ]}
      />
    </>
  )
}
