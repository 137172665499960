import * as React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import ReactGA from "react-ga4"
import { ThemeProvider } from "@allied/react-web/Theme"
import { MetaProvider } from "@allied/react-web/Meta"
import { CartProvider } from "../Cart"
import { routes } from "../../routes"
import "./App.css"

ReactGA.send({ hitType: "pageview", page: window.location.pathname })

const meta = {
  titleTemplate: "Aglogis - %s",
  titleFallback: "Aglogis - Logistics and supply chain management solutions.",
  descriptionFallback: "Streamlining logistics and supply chain management for businesses with efficient solutions, advanced technology, and reliable services.",
  keywordsFallback: "aglogis, logistic, supply, chain, management, business, solution, efficient, technology, singapore",
  typeFallback: "website",
  urlFallback: window.location.href,
  imageFallback: `${process.env.REACT_APP_PUBLIC_URL}/image/logo-meta.png`,
  markupSchemaFallback: {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Aglogis",
    "image": "https://aglogis.com/image/logo-meta.png",
    "@id": "https://aglogis.com/",
    "url": "https://aglogis.com/",
    "telephone": "+6565586042",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "10 Tuas Ave 6, Singapore 639298",
      "addressLocality": "Singapore",
      "postalCode": "639298",
      "addressCountry": "SG"
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday"
      ],
      "opens": "08:30",
      "closes": "17:30"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 1.3258101,
      "longitude": 103.6501101
    },
    "sameAs": [
      "https://www.facebook.com/aglogis/",
    ]
  },
  twitterSchemaFallback: {
    "card": "summary_large_image",
  }
}
export default function App(): React.JSX.Element {
  return (
    <ThemeProvider theme="aglogis">
      <MetaProvider meta={meta}>
        <CartProvider>
          <Router>
            <Routes>
              {
                routes.map((route, i) => {
                  return <Route {...route} key={`route-item-${i}`} />
                })
              }
            </Routes>
          </Router>
        </CartProvider>
      </MetaProvider>
    </ThemeProvider>
  )
}
