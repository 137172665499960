import * as React from "react"
import { Container } from "@allied/react-web/Container"
import { Link } from "@allied/react-web/Router"
import { Meta } from "@allied/react-web/Meta"
import { ButtonBack } from "../components/Navigation"
import { Breadcrumb } from "../components/Breadcrumb"
import { useAutoPosition } from "@allied/react-web/hooks"

export default function Policy(): React.JSX.Element {
  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: "Security and Privacy Policy",
          description: "Learn about our commitment to safeguarding your data and ensuring your privacy. Explore our comprehensive security measures and privacy practices to ensure a safe experience on our platform."
        }}
      </Meta>
      <Container size="md">
        <div className="my-6 flex flex-wrap flex-row gap-4">
          <ButtonBack>
            Back
          </ButtonBack>
          <Breadcrumb appendClassNames="font-roboto-mono font-light text-lg">
            {{
              items: [
                {
                  content: <>
                    <Link to="/" className="text-aglogis-primary">
                      Home
                    </Link>
                  </>
                },
                {
                  content: <>Security & Privacy Policy</>,
                }
              ]
            }}
          </Breadcrumb>
        </div>

        <div className="my-6 flex flex-col gap-8">
          <div>
            <h1 className="mb-4 font-bold text-3xl">Security & Privacy Policy</h1>
            <p>
              We at AG Logis Solutions Pte. Ltd. ("
              <span style={{ fontWeight: "bold" }}>AG Logis</span>" "
              <span style={{ fontWeight: "bold" }}>we</span>," "
              <span style={{ fontWeight: "bold" }}>Us</span>," "
              <span style={{ fontWeight: "bold" }}>Our</span>") know that our users
              and customers ("<span style={{ fontWeight: "bold" }}>you</span>," "
              <span style={{ fontWeight: "bold" }}>your</span>") care about how your
              personal information is collected, used and shared, and we take your
              privacy seriously. Please read this Security & Privacy Policy ("
              <span style={{ fontWeight: "bold" }}>Policy</span>") to understand how
              AG Logis may collect, use and disclose your personal data only for the
              purpose of business. By accepting the Policy and the User Agreement in
              registration, you expressly consent to our collection, storage, use
              and disclosure of your personal information as described in this
              Policy. This Policy is effective on 1st October 2018 for current
              users, and immediately upon acceptance by new users.
            </p>
          </div>

          <div>
            <h2 className="mb-4 font-bold text-3xl">1. Consent</h2>
            <p>
              By accessing the Site and/or providing personal data to AG Logis, you
              hereby acknowledge that you have read this Policy and that you consent
              to the collection, use and disclosure of your personal data by AG
              Logis in accordance with this Policy. If you do not consent to the
              terms of this Policy, please do not access the Site or provide your
              personal data to AG Logis.{" "}
            </p>
          </div>

          <div>
            <h2 className="mb-4 font-bold text-3xl">2. What is Personal Data?</h2>
            <p>
              Personal data refers to data, whether true or not, about an individual
              who can be identified from that data or from that data and other
              information to which the organisation has or is likely to have access.
            </p>
          </div>

          <div>
            <h2 className="mb-4 font-bold text-3xl">3. What personal data does AG Logis collect?</h2>
            <ul style={{ listStyle: "inside" }}>
              <li>Name</li>
              <li>Email Address</li>
              <li>Contact Number</li>
              <li>Delivery Address</li>
            </ul>
            <p>
              (a) Data that is voluntarily provided. AG Logis collects personal data
              that you voluntarily provide to us, whether through the Site or other
              platforms or channels (such as through email, telephone and other
              forms of communication). At various places on the Site, or in the
              course of obtaining the services provided by AG Logis, you may be
              required to provide certain personal data such as your full name,
              email address, delivery address, telephone number and other personal
              information, in order to access or use certain features or services of
              the Site, or to otherwise obtain our services. For example, personal
              data may be collected when you register an Account, make a purchase,
              promotion or survey, fill up a form, provide comments, suggestions or
              feedback, or when you contact our customer service via email, phone or
              other channels. You can always choose not to provide your personal
              data, but you may not be able to access or use certain features or
              services of the Site, or to obtain certain services from us. (b) Data
              that is sent automatically. In addition, AG Logis collects data that
              is sent automatically by your web browser, computer, mobile or tablet
              device. This may include your IP address, the address of the web page
              you were visiting when you accessed our Site, date and time of your
              visit, information about the device you are using and other
              information. Please check the settings of your browser and/or device
              if you want to know what data is automatically sent or if you wish to
              change your settings.{" "}
            </p>
          </div>

          <div>
            <h2 className="mb-4 font-bold text-3xl">4. How do we use your data?</h2>
            <p>
              Personal data collected by AG Logis from you may be used and/or shared
              with third parties (including related companies, affiliates and
              service providers)
            </p>
            <ul style={{ listStyle: "inside" }}>
              <li>
                To process, record, monitor and fulfil your orders and deliveries
              </li>
              <li>To plan and schedule routes for deliveries</li>
              <li>To verify and process your personal particulars and payments</li>
              <li>
                To communicate with you to confirm and update you on the status of
                your orders/deliveries, and respond to your queries and requests
              </li>
              <li>To update and backup records</li>
            </ul>
          </div>

          <div>
            <h2 className="mb-4 font-bold text-3xl">5. Who do we disclose your data to?</h2>
            <p>
              We do not rent, trade or sell your personal data. Your personal data
              will not be disclosed by AG Logis, except to:
            </p>
            <ul style={{ listStyle: "inside" }}>
              <li>Any affiliate or partner of AG Logis</li>
              <li>Logistics service providers</li>
            </ul>
            <p>
              We may disclose personal information to respond to legal requirements,
              enforce our policies, respond to claims that a listing or other
              content violates the rights of others, or protect anyone's rights,
              property, or safety. Such information will be disclosed in accordance
              with applicable laws and regulations.
            </p>
          </div>

          <div>
            <h2 className="mb-4 font-bold text-3xl">6. How long do we retain your data?</h2>
            <p>
              Personal data provided by you will be retained as long as the purpose
              for which the data was collected continues. Thereafter, AG Logis will
              destroy or delete the information, or remove the means by which the
              data can be associated with you, unless its retention is required to
              satisfy legal, regulatory, accounting or other business requirements
              or to protect AG Logis’s interests.{" "}
            </p>
          </div>

          <div>
            <h2 className="mb-4 font-bold text-3xl">7. Is your data secure?</h2>
            <p>
              As your Account is protected by a password for your privacy and
              security, we ask that you take steps to protect against unauthorised
              access or use of your Account. Please select and protect your password
              appropriately and limit access to your device and browser by signing
              off after you have finished accessing your Account. You are
              responsible for any loss, theft and compromise of your password and
              Account Information, and any activity on your Account that takes place
              through unauthorised password use.
            </p>
            <p>
              AG Logis understands the importance of keeping your personal data
              private and secure. We therefore put in place security arrangements to
              protect your privacy and personal data, in a manner that we believe is
              reasonably appropriate to prevent unauthorised access, collection,
              use, disclosure, copying, modification, disposal or similar risks.
              Please be informed that despite our best efforts, no security measures
              are impenetrable. Unauthorised entry or use, hardware or software
              failure, and other factors, may compromise the security of your
              personal data.{" "}
            </p>
            <p>
              AG Logis does not warrant or guarantee in any way that your personal
              information or private communications will always remain private
              and/or safe. AG Logis hereby disclaims any responsibility or liability
              directly or indirectly arising out of or in connection with, any loss,
              theft, or unauthorised access, collection, use, disclosure, copying,
              modification, disposal or similar actions with regard to any personal
              data held or maintained by us, except to the extent caused by our
              fault or negligence.
            </p>
          </div>

          <div>
            <h2 className="mb-4 font-bold text-3xl">8. Linked Sites</h2>
            <p>
              AG Logis may provide links to other sites ("
              <span style={{ fontWeight: "bold" }}>Linked Sites</span>") that may be
              of relevance and interest to you. AG Logis has no control over and are
              not responsible for the privacy practices or the content of such
              Linked Sites, and you hereby waive any claim against us with respect
              to the Linked Sites.
            </p>
          </div>

          <div>
            <h2 className="mb-4 font-bold text-3xl">9. Third Parties</h2>
            <p>
              Except as otherwise expressly included in this Policy, this document
              addresses only the use and disclosure of information we collect from
              you. If you disclose your information to others, whether they are
              buyers or sellers on our Website or other sites throughout the
              Internet, different rules may apply to their use or disclosure of the
              information you disclose to them. We do not control the privacy
              policies of third parties, and you are subject to the privacy policies
              of those third parties where applicable. We encourage you to ask
              questions before you disclose your personal information to others.
            </p>
          </div>

          <div>
            <h2 className="mb-4 font-bold text-3xl">10. Questions or Concerns</h2>
            <p>
              Please direct any questions or concerns that you may have regarding
              our handling of your personal data to: {""}
              <a href="mailto:admin@aglogis.com" style={{ color: "#AC1E23" }}>
                admin@aglogis.com
              </a>
            </p>
            <p>
              Please provide your name, contact number, email address and all
              relevant details along with your question or concern, so that we can
              review and respond to your question or concern in an efficient and
              effective manner.
            </p>
          </div>

          <div>
            <h2 className="mb-4 font-bold text-3xl">11. Right to amend Policy</h2>
            <p>
              AG Logis reserves the right to amend the prevailing Security & Privacy
              Policy at any time and will place any such amendments on this Site.
            </p>
          </div>

          <div>
            <h2 className="mb-4 font-bold text-3xl">12. Governing Law</h2>
            <p>
              This Policy is governed by and shall be construed in accordance with
              Singapore law including without limitation the provisions of the
              Evidence Act (Chapter 97), Electronic Transactions Act (Cap. 88) and
              the Personal Data Protection Act (PDPA), without giving effect to any
              principles of conflicts of law. You agree to submit to the
              non-exclusive jurisdiction of the Singapore courts.
            </p>
          </div>
        </div>
      </Container>
    </>
  )
}
