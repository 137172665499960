import * as React from "react"
import { Button } from "@allied/react-web/Button"
import { Checkbox } from "@allied/react-web/Check"
import { FaXmark } from "react-icons/fa6"

type ProductFilterProps = {
  principals?: string[]
  types?: string[]
  brands?: string[]
  onClose?: () => void
  onApply?: ({ filters }: { filters: FilterItem[] }) => void
}

type CheckItem = {
  value: string
  label: string
}

export type FilterItem = CheckItem & {
  type: string
}

export function ProductFilter(props: ProductFilterProps): React.JSX.Element {
  const {
    principals = [],
    brands = [],
    types = []
  } = props

  const [principalItems] = React.useState<CheckItem[]>([
    { value: "Bosch", label: "Bosch" },
    // { value: "Liberty", label: "Liberty" },
    { value: "Palma", label: "Palma" },
  ])
  const [typeItems] = React.useState<CheckItem[]>([
    { value: "Batteries", label: "Batteries" },
    { value: "AP Wipers", label: "AP Wipers" },
    { value: "BA Wipers", label: "BA Wipers" },
    { value: "BCA Wipers", label: "BCA Wipers" },
    { value: "BBA Wiper", label: "BBA Wiper" },
    { value: "Special Wipers", label: "Special Wipers" },
    { value: "Rear Wipers", label: "Rear Wipers" },
    { value: "Chargers", label: "Chargers" },
    { value: "Cabin Filters", label: "Cabin Filters" },
    { value: "Oil Filters", label: "Oil Filters" },
    { value: "Engine Oil", label: "Engine Oil" },
    { value: "Container Spare Part", label: "Container Spare Part" },
  ])
  const [brandItems] = React.useState<CheckItem[]>([
    { value: "Audi", label: "Audi" },
    { value: "Alfa Romeo", label: "Alfa Romeo" },
    { value: "Aston Martin", label: "Aston Martin" },
    { value: "Austin", label: "Austin" },
    { value: "Bentley", label: "Bentley" },
    { value: "BMW", label: "BMW" },
    { value: "BYD", label: "BYD" },
    { value: "Chevrolet", label: "Chevrolet" },
    { value: "Chrysler", label: "Chrysler" },
    { value: "Citroen", label: "Citroen" },
    { value: "Daihatsu", label: "Daihatsu" },
    { value: "Ferrari", label: "Ferrari" },
    { value: "Fiat", label: "Fiat" },
    { value: "Ford", label: "Ford" },
    { value: "Hino", label: "Hino" },
    { value: "Honda", label: "Honda" },
    { value: "Hyundai", label: "Hyundai" },
    { value: "Infiniti", label: "Infiniti" },
    { value: "Isuzu", label: "Isuzu" },
    { value: "Jaguar", label: "Jaguar" },
    { value: "Jeep", label: "Jeep" },
    { value: "KIA", label: "KIA" },
    { value: "Lamborghini", label: "Lamborghini" },
    { value: "Land Rover", label: "Land Rover" },
    { value: "Lexus", label: "Lexus" },
    { value: "MAN", label: "MAN" },
    { value: "Maserati", label: "Maserati" },
    { value: "Mazda", label: "Mazda" },
    { value: "Mercedes-Benz", label: "Mercedes-Benz" },
    { value: "MG", label: "MG" },
    { value: "MINI", label: "MINI" },
    { value: "Mitsubishi", label: "Mitsubishi" },
    { value: "Nissan", label: "Nissan" },
    { value: "Opel", label: "Opel" },
    { value: "Perodua", label: "Perodua" },
    { value: "Peugeot", label: "Peugeot" },
    { value: "Porsche", label: "Porsche" },
    { value: "Proton", label: "Proton" },
    { value: "Renault", label: "Renault" },
    { value: "Rolls-Royce", label: "Rolls-Royce" },
    { value: "Rover", label: "Rover" },
    { value: "Saab", label: "Saab" },
    { value: "Scania", label: "Scania" },
    { value: "SEAT", label: "SEAT" },
    { value: "Skoda", label: "Skoda" },
    { value: "Ssangyong", label: "Ssangyong" },
    { value: "Subaru", label: "Subaru" },
    { value: "Suzuki", label: "Suzuki" },
    { value: "Tesla", label: "Tesla" },
    { value: "Toyota", label: "Toyota" },
    { value: "UD", label: "UD" },
    { value: "Volkswagen", label: "Volkswagen" },
    { value: "Volvo", label: "Volvo" },
    { value: "Yutong", label: "Yutong" },
  ])
  const [filters, setFilters] = React.useState<FilterItem[]>([])

  React.useEffect(() => {
    const principalDict = new Set<string>()
    if (principals.length > 0) {
      principals.forEach((principal) => {
        principalDict.add(principal)
      })
    }

    const typeDict = new Set<string>()
    if (types.length > 0) {
      types.forEach((type) => {
        typeDict.add(type)
      })
    }

    const brandDict = new Set<string>()
    if (brands.length > 0) {
      brands.forEach((brand) => {
        brandDict.add(brand)
      })
    }

    setFilters([
      ...principalItems
        .filter((principal) => principalDict.has(principal.value))
        .map((principal) => {
          return {
            ...principal,
            type: "principal"
          }
        }),
      ...typeItems
        .filter((type) => typeDict.has(type.value))
        .map((type) => {
          return {
            ...type,
            type: "type"
          }
        }),
      ...brandItems
        .filter((brand) => brandDict.has(brand.value))
        .map((brand) => {
          return {
            ...brand,
            type: "brand"
          }
        }),
    ])
  }, [
    principals, principalItems,
    types, typeItems,
    brands, brandItems
  ])

  const removeFilter = (key: string) => {
    setFilters((prevState) => {
      return prevState.filter((filter) => {
        return filter.value !== key
      })
    })
  }

  const changeFilter = (item: FilterItem) => {
    setFilters((prevState) => {
      const exists = prevState.find((filter) => {
        return filter.value === item.value
      })
      if (exists) {
        return prevState.filter((filter) => {
          return filter.value !== item.value
        })
      }
      return [
        ...prevState,
        item
      ]
    })
  }

  const handleClearFilter = () => {
    setFilters([])
  }

  const handleCloseFilter = () => {
    props.onClose && props.onClose()
  }

  const handleApplyFilter = () => {
    props.onApply && props.onApply({
      filters
    })
  }

  return (
    <div className="p-5">
      <div className="flex flex-row justify-between my-4">
        <p>Filters</p>
        <button onClick={handleCloseFilter}>
          <FaXmark className="w-5 h-5" aria-hidden="true" />
        </button>
      </div>
      <div className="flex flex-row justify-between my-4">
        <p>Applied Filters:</p>
        <button onClick={handleClearFilter} className="text-aglogis-primary underline">
          Clear All
        </button>
      </div>
      <div className="my-4">
        {
          filters.length > 0 ?
            <ul className="bg-neutral-200">
              {
                filters.map((filter, i: number) => {
                  return (
                    <React.Fragment key={`filter-item-${i}`}>
                      <li className="flex flex-row justify-between items-center p-2">
                        <span className="text-sm">{filter.label}</span>
                        <button onClick={() => removeFilter(filter.value)}>
                          <FaXmark className="w-3 h-3 text-aglogis-primary" aria-hidden="true" />
                        </button>
                      </li>
                    </React.Fragment>
                  )
                })
              }
            </ul>
            :
            <p>No filters applied</p>
        }
        <Button onClick={handleApplyFilter} appendClassNames="my-4">
          Apply Filter
        </Button>
      </div>

      <div className="my-4">
        <p className="my-2 py-3 border-y border-aglogis-primary">
          Product Brand:
        </p>
        {
          principalItems.map((principal, i: number) => {
            return (
              <div key={`principal-item-${i}`} className="flex flex-row justify-start items-center gap-2">
                <Checkbox
                  id={`principal-${principal.value}`}
                  name={principal.value}
                  label={principal.label}
                  checked={filters.some((filter) => filter.value === principal.value && filter.type === "principal")}
                  onChange={() => {
                    changeFilter({
                      ...principal,
                      type: "principal"
                    })
                  }} />
              </div>
            )
          })
        }

        <p className="my-2 py-3 border-y border-aglogis-primary">
          Product Type:
        </p>
        {
          typeItems.map((type, i: number) => {
            return (
              <div key={`type-item-${i}`} className="flex flex-row justify-start items-center gap-2">
                <Checkbox
                  id={`type-${type.value}`}
                  name={type.value}
                  label={type.label}
                  checked={filters.some((filter) => filter.value === type.value && filter.type === "type")}
                  onChange={() => {
                    changeFilter({
                      ...type,
                      type: "type"
                    })
                  }} />
              </div>
            )
          })
        }

        <p className="my-2 py-3 border-y border-aglogis-primary">
          Car Brand:
        </p>
        {
          brandItems.map((brand, i: number) => {
            return (
              <div key={`brand-item-${i}`} className="flex flex-row justify-start items-center gap-2">
                <Checkbox
                  id={`brand-${brand.value}`}
                  name={brand.value}
                  label={brand.label}
                  checked={filters.some((filter) => filter.value === brand.value && filter.type === "brand")}
                  onChange={() => {
                    changeFilter({
                      ...brand,
                      type: "brand"
                    })
                  }} />
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
