import * as React from "react"
import { useParams, useNavigate } from "react-router-dom"
import { BsDash, BsPlus } from "react-icons/bs"
import { FaChartLine } from "react-icons/fa"
import { useAutoPosition } from "@allied/react-web/hooks"
import { Accordion } from "@allied/react-web/Accordion"
import { Button, ButtonLink } from "@allied/react-web/Button"
import { Container } from "@allied/react-web/Container"
import { Meta } from "@allied/react-web/Meta"
import { Link } from "@allied/react-web/Router"
import { CartContext } from "../components/Cart"
import { ButtonBack } from "../components/Navigation"
import { Breadcrumb } from "../components/Breadcrumb"
import { toast } from "../components/Toast"
import { RESOURCE_EXISTS, RESOURCE_NOTFOUND } from "../services/Status"
import { ProductService } from "../services/static/Product"
import { ComparationService } from "../services/local/Comparation"

const productService = new ProductService()
const comparationService = new ComparationService(productService)

type ProductItem = {
  sku: string
  slug: string
  name: string
  thumbnail: any
  description: string
  detail: string
  information: string
  uom: string
  principals: string[]
  brands: string[]
  types: string[]
}

export default function ProductDetail(): React.JSX.Element {
  const [product, setProduct] = React.useState<ProductItem>(null)
  const [quantity, setQuantity] = React.useState(0)
  const { slug } = useParams()
  const { addItem } = React.useContext(CartContext)
  const navigate = useNavigate()

  React.useEffect(() => {
    async function getProduct() {
      const getProduct = await productService.GetProduct({
        slug
      })
      if (getProduct.error) {
        return
      }
      setProduct(getProduct.data)
    }

    getProduct()
  }, [slug])

  const handleCompareItem = () => {
    async function addComparation(slug: string) {
      const addComparation = await comparationService.AddItem({
        slug
      })
      if (addComparation.error) {
        switch (addComparation.error.code) {
          case RESOURCE_NOTFOUND:
            alert("Product is not valid")
            break
          case RESOURCE_EXISTS:
            alert("Product is already added to the compare list.")
            break
          default:
            console.error(addComparation.error)
            alert("Unexpected error happened")
        }
        return
      }

      navigate(`/compare`)
    }

    addComparation(product.slug)
  }

  const handleQuantityUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(parseInt(e.target.value))
  }

  const handleDecreaseItem = () => {
    setQuantity((prevState) => {
      if (prevState - 1 < 0) {
        return 0
      }
      return prevState - 1
    })
  }

  const handleIncreaseItem = () => {
    setQuantity((prevState) => {
      if (prevState + 1 > 99999) {
        return 99999
      }
      return prevState + 1
    })
  }

  const handleAddToCart = () => {
    if (quantity < 1) {
      toast.warn(
        "Product quantity should be more than 1 item",
        { className: "toast-mesasge" }
      )
      return
    }

    addItem(product.slug, quantity)
  }

  useAutoPosition()

  if (!product) {
    return (
      <>
        <div className="h-screen flex flex-col justify-center items-center">
          <div className="my-4 flex flex-col gap-4 justify-center items-center">
            <h1 className="text-8xl">
              404
            </h1>
            <h2 className="text-2xl">
              Oops! Page not found
            </h2>
          </div>
          <div className="my-6">
            <ButtonLink to="/" size="lg">
              Take me back to the homepage
            </ButtonLink>
          </div>
        </div>
      </>
    )
  }

  const infoItems = []
  if (product.detail) {
    infoItems.push({
      header: <p className="text-2xl md:!text-3xl">Details</p>,
      content: <p className="font-light font-roboto-mono text-gray-600">{product.detail}</p>,
      opened: true,
    })
  }
  if (product.information) {
    infoItems.push({
      header: <p className="text-2xl md:!text-3xl">More Information</p>,
      content: <p className="font-light font-roboto-mono text-gray-600">{product.information}</p>,
    })
  }

  return (
    <>
      <Meta>
        {{
          title: product.name,
          image: product.thumbnail,
          description: product.description
        }}
      </Meta>
      <Container size="md">
        <div className="my-6 flex flex-wrap flex-row gap-4">
          <ButtonBack>
            Back
          </ButtonBack>
          <Breadcrumb appendClassNames="font-roboto-mono font-light text-lg">
            {{
              items: [
                {
                  content: <>
                    <Link to="/" className="text-aglogis-primary">
                      Home
                    </Link>
                  </>
                },
                {
                  content: <>
                    <Link to="/products" className="text-aglogis-primary">
                      All Products
                    </Link>
                  </>
                },
                {
                  content: <>{product.name}</>,
                }
              ]
            }}
          </Breadcrumb>
        </div>

        <div className="my-4 grid grid-cols-12 gap-4">
          <div className="col-span-12 md:!col-span-6 lg:!col-span-4">
            <img src={product.thumbnail} alt={product.name} style={{
              border: "1px solid gray",
              borderRadius: "15px",
              padding: "10px",
              display: "inline-block",
              maxWidth: "100%",
              maxHeight: "100%",
              marginBottom: "20px",
              marginRight: "20px",
              width: "500px",
            }} />
          </div>
          <div className="col-span-12 md:!col-span-6 lg:!col-span-8">
            <div className="flex flex-col gap-3">
              <h1 className="text-2xl md:!text-3xl font-bold">
                {product.name}
              </h1>
              <p className="text-xl">
                SKU#: {product.sku}
              </p>
              <div className="block">
                <button className="flex flex-row justify-start items-center gap-2" onClick={handleCompareItem}>
                  <FaChartLine className="w-6 h-6" aria-hidden="true" />
                  <span className="text-lg">Compare Products</span>
                </button>
              </div>
            </div>

            <hr className="my-4 w-full text-aglogis-primary" />

            <div className="flex flex-col gap-3">
              <p className="text-lg">
                UOM: {product.uom}
              </p>
              <div className="flex flex-row gap-2">
                <Button onClick={handleDecreaseItem}>
                  <BsDash className="w-5 h-5" aria-hidden="true" />
                </Button>
                <input className="w-32 flex justify-center items-center text-center p-2 rounded-md border border-gray-400"
                  value={quantity}
                  onChange={handleQuantityUpdate} />
                <Button onClick={handleIncreaseItem}>
                  <BsPlus className="w-5 h-5" aria-hidden="true" />
                </Button>
              </div>
              <div>
                <Button size="lg" onClick={handleAddToCart}>
                  Add to Cart
                </Button>
              </div>
            </div>
          </div>
        </div>

        {
          infoItems.length > 0 &&
          <div className="my-2">
            <Accordion alwaysOpen>
              {{
                items: infoItems
              }}
            </Accordion>
          </div>
        }
      </Container>
    </>
  )
}
