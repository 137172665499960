import * as React from "react"
import { FaChevronRight } from "react-icons/fa6"
import { ButtonLink } from "@allied/react-web/Button"
import { Container } from "@allied/react-web/Container"
import { Meta } from "@allied/react-web/Meta"
import { Image } from "@allied/react-web/Image"
import { Link } from "@allied/react-web/Router"
import { useAutoPosition } from "@allied/react-web/hooks"
import { ProductSearch } from "../components/Product"
import { ProductService } from "../services/static/Product"
import warehouseBackground from "./../assets/image/heroes/warehouse.jpg"
import containerBackground from "./../assets/image/heroes/container.jpg"

type BrandItem = {
  name: string
  slug: string
  logo: any
}

type PrincipalItem = {
  name: string
  slug: string
  description: string
  brief: string
  logo: any
  website: string
}

const productService = new ProductService()

export default function Home(): React.JSX.Element {
  const [brands, setBrands] = React.useState<BrandItem[]>([])
  const [principals, setPrincipals] = React.useState<PrincipalItem[]>([])

  React.useEffect(() => {
    async function searchBrand() {
      const searchBrand = await productService.SearchBrand({
        featured: true,
      })
      if (searchBrand.error) {
        return
      }
      setBrands(searchBrand.data.items)
    }

    async function searchPrincipal() {
      const searchPrincipal = await productService.SearchPrincipal({
        featured: true,
      })
      if (searchPrincipal.error) {
        return
      }
      setPrincipals(searchPrincipal.data.items)
    }

    Promise.all([searchBrand(), searchPrincipal()])
  }, [])

  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: "Logistics and supply chain management solutions.",
          description: "Streamlining logistics and supply chain management for businesses with efficient solutions, advanced technology, and reliable services."
        }}
      </Meta>
      <div className="flex flex-col">
        <div className="relative flex justify-center items-center w-full xs:!h-full" style={{ height: "32rem" }}>
          <img src={warehouseBackground} alt="Logo" className="w-full h-full object-cover" />
          <div className="absolute top-3/4 left-1/2 w-2/3 md:!w-2/3 lg:!w-3/5 xl:!w-2/5" style={{ transform: "translate(-50%, -50%)" }}>
            <ProductSearch />
          </div>
        </div>

        <hr className="my-10 w-full border-aglogis-red" />

        <Container size="md">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 md:!col-span-6">
              <img src={containerBackground} alt="Container"
                className="w-full h-full" width={4} height={4} />
            </div>
            <div className="col-span-12 md:!col-span-6">
              <div className="flex flex-col gap-8 font-open-sans">
                <div className="flex flex-col gap-3">
                  <h1 className="text-3xl md:!text-4xl lg:!text-5xl font-bold">
                    Buy & Sell / Modify Containers
                  </h1>
                  <p className="text-xl md:!text-2xl text-gray-600">
                    Elevate your Container Operations with AG Logis
                  </p>
                </div>

                <div className="flex flex-col gap-3">
                  <h2 className="text-xl md:!text-3xl font-bold">Our Services</h2>
                  <ul className="flex flex-col gap-3 text-lg md:!text-xl text-gray-600">
                    <li>Container Trading</li>
                    <li>Container Leasing</li>
                    <li>One-trip Units</li>
                  </ul>
                </div>

                <div className="flex flex-col gap-3">
                  <h2 className="text-xl md:!text-3xl font-bold">Why Choose Us?</h2>
                  <ul className="flex flex-col gap-3 text-lg md:!text-xl text-gray-600">
                    <li>Tailored Solution to Meet Your Unique Needs</li>
                    <li>Responsive and Reliable Team</li>
                    <li>Trusted and Committed to Long-Term Partnerships</li>
                  </ul>
                </div>

                <div className="flex flex-row flex-wrap gap-2">
                  <ButtonLink to="/container-enquiry">
                    Container Enquiry
                  </ButtonLink>
                  <ButtonLink to="/products?types=Container+Spare+Part">
                    Spare Part Enquiry
                  </ButtonLink>
                </div>
              </div>
            </div>
          </div>

          <div className="my-8 flex flex-col justify-center items-center">
            <hr className="w-1/2 border-aglogis-red" />
          </div>

          <div className="my-2 flex flex-row justify-between items-center">
            <Link to="/products" className="text-2xl md:!text-4xl font-bold">
              Car Brands
            </Link>

            <Link to="/car-brands" className="flex flex-row justify-between items-center gap-2 text-base md:!text-lg text-aglogis-primary font-bold">
              <span>View More</span>
              <FaChevronRight className="w-4 h-4" aria-hidden="true" />
            </Link>
          </div>

          <div className="my-4 grid grid-cols-12 gap-6">
            {
              brands.map((brand, i: number) => {
                return (
                  <div key={`brand-item-${i}`} className="col-span-6 sm:!col-span-6 lg:!col-span-4 xl:!col-span-4">
                    <Link to={`/car-products/${brand.slug}`}>
                      <div className="bg-white rounded-xl border-1 shadow-md">
                        <div className="flex flex-col gap-4 justify-center items-center">
                          <div className="w-full h-full p-2">
                            <img src={brand.logo} alt={brand.name}
                              className="w-full h-36 md:!h-52 object-cover"
                              width={4} height={4} />
                          </div>
                          <h2 className="my-4 text-lg md:!text-xl">
                            {brand.name}
                          </h2>
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              })
            }
          </div>

          <div className="my-8 flex flex-col justify-center items-center">
            <hr className="w-1/2 border-aglogis-red" />
          </div>

          <div className="my-2 flex flex-row justify-between items-center">
            <Link to="/principals" className="text-2xl md:!text-4xl font-bold">
              Choose by Brand
            </Link>
          </div>

          <div className="my-4 grid grid-cols-12 gap-6">
            {
              principals.map((principal, i: number) => {
                return (
                  <div key={`principal-item-${i}`} className="col-span-12 sm:!col-span-6 lg:!col-span-4">
                    <div className="w-full h-full">
                      <div className="my-4">
                        <Image src={principal.logo} alt={principal.name}
                          className="w-full h-48 p-4 border border-gray-400 rounded-3xl"
                          width={16} height={9} lazy />
                      </div>
                      <div className="my-4 flex flex-col gap-4 min-h-36 sm:!min-h-72 lg:!min-h-80 xl:!min-h-52">
                        <h2 className="text-xl md:!text-3xl font-normal">
                          {principal.name}
                        </h2>
                        <p className="text-lg md:!text-2xl text-gray-500">
                          {principal.brief}
                        </p>
                      </div>
                      <div className="my-4 flex flex-row gap-14 justify-center items-center">
                        <Link to={`principals`}
                          className="flex flex-row justify-between items-center gap-2 text-aglogis-primary">
                          <span className="text-xl font-bold">Learn</span>
                          <FaChevronRight className="w-3 h-3" aria-hidden="true" />
                        </Link>
                        <Link to={`/products?principals=${principal.name}`}
                          className="flex flex-row justify-between items-center gap-2 text-aglogis-primary">
                          <span className="text-xl font-bold">Shop</span>
                          <FaChevronRight className="w-3 h-3" aria-hidden="true" />
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </Container>
      </div>
    </>
  )
}